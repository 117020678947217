<template>
  <div>
    <Modal class="overflow-y-hidden relative" height="350px" width="500px" :modalVisible="isModalRequestCategory" @close="hideModalRequestCategory()" id="assignClass">
      <template slot="modal-content">
        <h1 class="text-2xl font-bold mb-3 mt-5">Request Category</h1>
        <div class="mb-5">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Marketplace</label>
          <Dropdown :options="storeOptions" optionLabel="name" v-model="selectedStore" :default="selectedStore" placeholder="Select marketplace" />
        </div>
        <div class="mb-5">
          <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Category <span v-if="!selectedStore" class="inline-block text-neutral-300">(Marketplace required)</span></label>
          <Dropdown maxHeight="80px" :disabled="!selectedStore" :options="categoryOptions" optionLabel="name" v-model="selectedCategory" :default="selectedCategory" placeholder="Select category" />
        </div>
        <div class="flex justify-center items-center pt-3">
          <Button :disabled="!canSubmit" class="mr-5" buttonText="Submit" type="secondary" @action="requestCategory()" />
          <Button buttonText="Cancel" type="tertiary" @action="hideModalRequestCategory()" />
        </div>
      </template>
    </Modal>
    <div class="kelas-new-form pb-6 w-full min-h-contain">
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">Class Category</h1>
      </div>
      <div class="bg-white p-4 shadow-small rounded-lg card">
        <div class="px-2 flex-1 flex-shrink-0">
          <div class="flex justify-between border-b border-neutral-50 pb-2 mb-4">
            <p class="font-semibold flex gap-1 items-center">
              <span class="-ml-1"><Label color="yellow" /></span>Category Request
            </p>
            <div>
              <Button buttonText="Request Category" size="full" @action="showModalRequestCategory" />
            </div>
          </div>
          <div class="mb-6">
            <div class="grid grid-cols-6 justify-start gap-2 mb-4">
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Marketplace</label>
                <Dropdown :options="storeOptions" optionLabel="name" v-model="selectedFilterStore" :default="selectedFilterStore" placeholder="All" />
              </div>
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Category</label>
                <Dropdown :options="categoryOptions" optionLabel="name" :disabled="!selectedFilterStore" v-model="selectedFilterCategory" :default="selectedFilterCategory" placeholder="All" />
              </div>
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Status</label>
                <Dropdown :options="statusOptions" optionLabel="name" v-model="selectedFilterStatus" :default="selectedFilterStatus" placeholder="All" />
              </div>
              <div>
                <label class="text-xs pl-3 font-medium text-neutral-500 text-left block mb-1">Published</label>
                <Dropdown :options="publishedOptions" optionLabel="name" v-model="selectedFilterPublished" :default="selectedFilterPublished" placeholder="All" />
              </div>
              <div class="grid grid-cols-2 h-full mt-5 ml-2">
                <Button buttonText="Filter" type="secondary" @action="filterList" additionalClass="bg-yellow h-11 mr-2" />
                <Button v-show="isDefaultFilter" buttonText="Clear" type="tertiary" @action="clearFilter" additionalClass=" h-11" />
              </div>
            </div>
            <vue-good-table
              v-if="categoryList.length > 0"
              mode="remote"
              :pagination-options="{
                enabled: true,
                mode: 'pages',
                perPage: size,
                position: 'bottom',
                perPageDropdown: [5, 10],
                dropdownAllowAll: false,
                nextLabel: 'next',
                prevLabel: 'prev',
                rowsPerPageLabel: 'Rows per page',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All',
                setCurrentPage: pageTable
              }"
              :totalRows="totalRows"
              :rows="categoryList"
              :columns="tableHeader"
              :sort-options="{
                enabled: false
              }"
              styleClass="vgt-table bordered my-table"
              @on-page-change="onPageChange"
              @on-per-page-change="onSizeChange"
            />
            <div v-else class="border rounded-lg h-64 flex justify-center items-center">
              <div class="bg-pink">
                <p class="text-center text-neutral-400">No data available</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import { mapActions } from 'vuex'
// import { showVueToast } from '@/utils'
export default {
  name: 'StudentProfile',
  components: {
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Label: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Label')
  },
  data: function () {
    return {
      isLoading: true,
      sizeChange: false,
      isModalRequestCategory: false,
      selectedFilterStore: null,
      selectedFilterCategory: null,
      selectedFilterStatus: null,
      selectedFilterPublished: null,
      selectedStore: null,
      selectedCategory: null,
      storeOptions: [],
      categoryOptions: [],
      statusOptions: [
        { id: 'REQUESTED', name: 'Requested' },
        { id: 'REJECTED', name: 'Rejected' },
        { id: 'APPROVED', name: 'Approved' }
      ],
      publishedOptions: [
        { id: true, name: 'Published' },
        { id: false, name: 'Not Published' }
      ],
      categoryList: [],
      totalRows: 0,
      size: 10,
      currentPage: 0
    }
  },
  computed: {
    customerId() {
      return localStorage.getItem('client')
    },
    coreClassId() {
      return this.$route.params.id
    },
    tableHeader() {
      return [
        { field: 'store', label: 'Marketplace' },
        { field: 'category', label: 'Category' },
        { field: 'status', label: 'Status' },
        { field: 'published', label: 'Published' },
        { field: 'lastUpdate', label: 'Last Update' }
      ]
    },
    pageTable() {
      return this.currentPage + 1
    },
    isDefaultFilter() {
      return this.selectedFilterStore || this.selectedFilterCategory || this.selectedFilterStatus || this.selectedFilterPublished ? true : false
    },
    canSubmit() {
      return this.selectedStore && this.selectedCategory
    }
  },
  watch: {
    selectedStore(val) {
      if (val) {
        this.selectedCategory = null
        this.getCategoryList(val.id)
      }
    },
    selectedFilterStore(val) {
      if (val) {
        this.selectedFilterCategory = null
        this.getCategoryList(val.id)
      }
    }
  },
  mounted() {
    this.getStoreList()
    this.getClassCategory()
  },
  methods: {
    ...mapActions('common', ['showLoading', 'hideLoading']),
    ...mapActions('kelas', ['REQUEST_CATEGORY', 'GET_STORE_LIST', 'GET_CATEGORY_LIST', 'GET_CLASS_CATEGORY_LIST']),
    onPageChange(params) {
      this.currentPage = params.currentPage - 1
      this.getClassCategory()
    },
    onSizeChange(params) {
      if (!this.sizeChange) {
        this.sizeChange = true
        this.size = params.currentPerPage
        this.currentPage = 0
        this.getClassCategory()
      }
    },
    publishedFriendly(published) {
      let forReturn = '-'
      this.publishedOptions.forEach((element) => {
        if (element.id === published) forReturn = element.name
      })
      return forReturn
    },
    statusFriendly(status) {
      if (status) {
        const arrayStatus = status.split(' ')
        let newArrayStatus = []
        for (let i = 0; i < arrayStatus.length; i++) {
          const subArrayStatus = arrayStatus[i].split('_')
          if (subArrayStatus.length > 1) {
            let subNewArrayStatus = []
            for (let j = 0; j < subArrayStatus.length; j++) {
              newArrayStatus.push(`${subArrayStatus[j][0].toUpperCase()}${subArrayStatus[j].substr(1).toLowerCase()}`)
            }
          } else {
            newArrayStatus.push(`${arrayStatus[i][0].toUpperCase()}${arrayStatus[i].substr(1).toLowerCase()}`)
          }
        }
        return newArrayStatus.join(' ')
      } else {
        return '-'
      }
    },
    showModalRequestCategory() {
      this.isModalRequestCategory = true
    },
    hideModalRequestCategory() {
      this.selectedStore = this.selectedCategory = null
      this.isModalRequestCategory = false
    },
    getStoreList() {
      this.GET_STORE_LIST({
        params: queryString.stringify({
          page: 0,
          size: 1000
        })
      }).then((res) => {
        this.storeOptions = []
        res.data.data.data.forEach((opt) => {
          this.storeOptions.push({
            id: opt.id,
            name: opt.marketplaceName
          })
        })
      })
    },
    getCategoryList(storeId) {
      this.GET_CATEGORY_LIST({ customerId: storeId }).then((res) => (this.categoryOptions = res.data.data))
    },
    getClassCategory() {
      this.showLoading()
      this.GET_CLASS_CATEGORY_LIST({
        customerId: this.customerId,
        coreClassId: this.coreClassId,
        params: queryString.stringify({
          page: this.currentPage,
          size: this.size,
          categoryId: this.selectedFilterCategory ? this.selectedFilterCategory.id : '',
          storeId: this.selectedFilterStore ? this.selectedFilterStore.id : '',
          status: this.selectedFilterStatus ? this.selectedFilterStatus.id : '',
          isPublished: this.selectedFilterPublished ? this.selectedFilterPublished.id : ''
        })
      }).then((res) => {
        this.totalRows = res.data.data.totalRows
        this.size = res.data.data.size
        this.currentPage = res.data.data.currentPage
        this.categoryList = []
        res.data.data.data.forEach((category) => {
          this.categoryList.push({
            id: category.id,
            store: category.storeName,
            category: category.categoryBreadcrumb,
            status: this.statusFriendly(category.status),
            published: category.isPublished === undefined || category.isPublished === null ? '-' : this.publishedFriendly(category.isPublished),
            lastUpdate: moment(category.lastUpdatedDate).format('DD MMM YYYY HH:mm')
          })
        })
        setTimeout(() => {
          this.isLoading = this.sizeChange = false
          this.hideLoading()
        }, 1000)
      })
    },
    requestCategory() {
      this.showLoading()
      this.REQUEST_CATEGORY({
        customerId: this.customerId,
        coreClassId: this.coreClassId,
        payload: {
          storeId: this.selectedStore.id,
          categoryId: this.selectedCategory.id
        }
      })
        .then(() => {
          this.showVueToast('Request category successfully!', 'success', 2000)
          this.hideModalRequestCategory()
          this.getClassCategory()
        })
        .catch((res) => {
          if (res.errors) {
            this.showVueToast(`${res.errors.error}!`, 'error', 2000)
          } else {
            this.showVueToast('Failed to request category, please try again later!', 'error', 2000)
          }
        })
      setTimeout(() => {
        this.hideLoading()
      }, 2500)
    },
    filterList() {
      this.currentPage = 0
      this.getClassCategory()
    },
    clearFilter() {
      this.selectedFilterStore = this.selectedFilterCategory = this.selectedFilterStatus = this.selectedFilterPublished = null
      this.currentPage = 0
      this.getClassCategory()
    },
    showVueToast(text, status, duration) {
      this.$toasted.show(text, {
        position: 'top-center',
        type: status,
        duration,
        theme: 'bubble',
        singleton: true
      })
    }
  }
}
</script>
